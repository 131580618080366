import Header from '@amzn/awsui-components-react/polaris/header';
import KeyValuePairs, {
  KeyValuePairsProps,
} from '@amzn/awsui-components-react/polaris/key-value-pairs';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
  Attendance,
  AttendanceStatus,
  Event,
  Option,
  Question,
  Shift,
  Signup,
} from '@amzn/red-velvet-api';
import { useTranslation } from 'react-i18next';
import { useTimezone } from '../../hooks/timezone';
import { asDateString, asTimeString } from '../../utils/dateFormatting';
import { getShiftLengthLabel } from '../eventDisplay';
import { ShiftActions } from './actions';
import { type i18n, TFunction } from 'i18next';
import { useLatestAttendanceState } from '../../hooks/attendance/attendanceMutation';

export interface ModalData {
  loading: boolean;
  waiverLinkOpened: boolean;
  formValidated: boolean;
  questionAnswers?: {
    question: Question;
    options: Option[];
    answer?: Option;
  }[];
}

export function ShiftHeader({ shift }: { shift: Shift }) {
  return <Header variant="h3">{shift.name}</Header>;
}

interface ShiftBodyProps {
  event: Event;
  shift: Shift;
  attendance?: Attendance;
  displayAttributes: DISPLAY_ATTRIBUTES_TYPE[];
}
export function ShiftBody({ event, shift, attendance, displayAttributes }: ShiftBodyProps) {
  const latestAttendance = useLatestAttendanceState(shift.shiftId, attendance);

  const info = useShiftInfo({
    event,
    shift,
    attendance: latestAttendance,
    displayAttributes,
  });

  return (
    //THIS CLASS IS REQUIRED FOR INTEGRATION TESTING TO REGISTER THE EXISTANCE OF SHIFTS
    <div className="shift">
      <div className="shiftBody">
        <SpaceBetween direction="vertical" size="s">
          {info.map((item, idex) =>
            !item.type ? (
              <div key={idex}>
                <b>{item.label}:</b> {item.value}
              </div>
            ) : null
          )}
        </SpaceBetween>
      </div>
    </div>
  );
}

function formatAttendanceTimeDisplay(minutes: number, t: TFunction): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours === 0) {
    return t('eventDetails.shiftDisplay.attendanceTimeInMinutes', {
      minutes: remainingMinutes,
    });
  }
  if (remainingMinutes === 0) {
    return t('eventDetails.shiftDisplay.attendanceTimeInHour', {
      hours: hours,
    });
  }
  return t('eventDetails.shiftDisplay.attendanceTimeInHourAndMinutes', {
    hours: hours,
    minutes: remainingMinutes,
  });
}
export const DISPLAY_LOCATION_INFO = 'location';
export const DISPLAY_DATE_INFO = 'dateInfo';
export const DISPLAY_SIGNUPS_REMAINING_INFO_WITHOUT_CONDITION = 'signupsRemaining';
export const DISPLAY_SIGNUPS_REMAINING_INFO_WITH_CONDITION = 'signupsRemaining';
export const DISPLAY_ATTENDANCE_INFO = 'attendance';
export type DISPLAY_ATTRIBUTES_TYPE =
  | typeof DISPLAY_DATE_INFO
  | typeof DISPLAY_LOCATION_INFO
  | typeof DISPLAY_ATTENDANCE_INFO
  | typeof DISPLAY_SIGNUPS_REMAINING_INFO_WITHOUT_CONDITION
  | typeof DISPLAY_SIGNUPS_REMAINING_INFO_WITH_CONDITION;

interface UseShiftInfoProps {
  event: Event;
  shift: Shift;
  attendance?: Attendance | undefined;
  displayAttributes: DISPLAY_ATTRIBUTES_TYPE[];
}

function generateDateInfo(
  event: Event,
  shift: Shift,
  selectedTimezone: string,
  t: TFunction,
  i18n: i18n
) {
  const startDate = shift.startDateTime ? new Date(shift.startDateTime) : null;
  const endDate = shift.endDateTime ? new Date(shift.endDateTime) : null;
  if (startDate && endDate) {
    const diffTime = endDate.getTime() - startDate.getTime();

    const timeString = asTimeString(startDate, selectedTimezone, i18n.language, {
      removeTimeZone: true,
    });
    const localeStartDateString = asDateString(startDate, selectedTimezone, i18n.language);
    const eventLocalTimeStringNoTz = asTimeString(startDate, event.timeZone, i18n.language, {
      removeTimeZone: true,
    });
    const eventLocalTimeString = asTimeString(startDate, event.timeZone, i18n.language, {
      removeTimeZone: true,
    });
    const localTimeIdentical = eventLocalTimeStringNoTz === timeString;
    return [
      {
        label: t('eventDetails.shiftDisplay.date'),
        value: localeStartDateString,
      },
      {
        label: t('eventDetails.shiftDisplay.startTime2'),
        value: (
          <>
            {timeString}
            {localTimeIdentical ? null : (
              <div className="shiftLocalDateTime">
                <small>
                  <b>{t('eventDetails.shiftDisplay.localStartTime')}:</b> {eventLocalTimeString}
                </small>
              </div>
            )}
          </>
        ),
      },
      {
        label: t('eventDetails.shiftDisplay.length'),
        value: getShiftLengthLabel(t, diffTime),
      },
    ];
  } else {
    return [
      {
        label: t('eventDetails.shiftDisplay.date'),
        value: t('eventDisplay.ongoingEvent'),
      },
    ];
  }
}

function generateAttendanceInfo(attendance: Attendance | undefined, t: TFunction) {
  const attendanceInfo = attendance
    ? attendance.attendanceStatus === AttendanceStatus.DID_NOT_ATTEND
      ? t('eventDetails.shiftDisplay.didNotAttendantInfo')
      : formatAttendanceTimeDisplay(attendance.minutes, t)
    : t('eventDetails.shiftDisplay.NoAttendanceTrackInfo');
  return [
    {
      label: t('eventDetails.shiftDisplay.attendance'),
      value: attendanceInfo,
    },
  ];
}

function generateLocationInfo(event: Event, t: TFunction): KeyValuePairsProps.Item[] | null {
  const locationCityWithProvince =
    event.location?.city && event.location?.province
      ? `${event.location.city}, ${event.location.province}`
      : event.location?.city || event.location?.province || null;
  if (locationCityWithProvince) {
    return [
      {
        label: t('eventDetails.location'),
        value: locationCityWithProvince,
      },
    ];
  }
  return null;
}

function generateSignupsRemainingInfo(shift: Shift, t: TFunction): KeyValuePairsProps.Item[] {
  const hasLimitedSpots = shift.signupsRemaining && shift.maxSignup;
  const signupsRemaining: KeyValuePairsProps.Item[] = [
    {
      label: t('eventDetails.shiftDisplay.spotsAvailable'),
      value: hasLimitedSpots ? shift.signupsRemaining : t('eventDetails.shiftDisplay.unlimited'),
    },
  ];
  if (hasLimitedSpots) {
    signupsRemaining.unshift({
      label: t('eventDetails.shiftDisplay.signedUp'),
      value: (shift.maxSignup ?? 0) - (shift.signupsRemaining ?? 0),
    });
  }
  return signupsRemaining;
}

function useShiftInfo({
  event,
  shift,
  attendance,
  displayAttributes,
}: UseShiftInfoProps): KeyValuePairsProps.Item[] {
  const { t, i18n } = useTranslation();
  const selectedTimezone = useTimezone();
  let totalInfo: KeyValuePairsProps.Item[] = [];
  const allAttributes: Record<DISPLAY_ATTRIBUTES_TYPE, KeyValuePairsProps.Item[] | null> = {
    dateInfo: generateDateInfo(event, shift, selectedTimezone, t, i18n),
    location: generateLocationInfo(event, t),
    signupsRemaining: generateSignupsRemainingInfo(shift, t),
    attendance: generateAttendanceInfo(attendance, t),
  };
  // Combine all attributes into the totalInfo array based on displayedAttributes
  displayAttributes.forEach((attribute) => {
    const attributeInfo = allAttributes[attribute];
    if (Array.isArray(attributeInfo)) {
      // Check if it's an array
      totalInfo = [...totalInfo, ...attributeInfo];
    }
  });

  return totalInfo;
}

interface ShiftInfoProps {
  event: Event;
  shift: Shift;
  attendance?: Attendance;
  displayAttributes: DISPLAY_ATTRIBUTES_TYPE[];
}

export function ShiftInfo({ event, shift, attendance, displayAttributes }: ShiftInfoProps) {
  const { t } = useTranslation();
  const info = useShiftInfo({ event, shift, attendance, displayAttributes });

  return (
    //THIS CLASS IS REQUIRED FOR INTEGRATION TESTING TO REGISTER THE EXISTENCE OF SHIFTS
    <div className="shift">
      <div className="shiftBody">
        <SpaceBetween direction="vertical" size="l">
          <KeyValuePairs
            columns={1}
            items={[
              {
                label: t('eventDetails.shiftDisplay.shiftName'),
                value: shift.name,
              },
            ]}
          />
          <KeyValuePairs columns={4} items={info} />
        </SpaceBetween>
      </div>
    </div>
  );
}

export type ShiftFooterProps = {
  event: Event;
  shift: Shift;
  signup?: Signup;
  attendance?: Attendance;
};

export function ShiftFooter({ event, shift, signup, attendance }: ShiftFooterProps) {
  return (
    <div className="shiftFooter">
      <ShiftActions event={event} shift={shift} signup={signup} attendance={attendance} />
    </div>
  );
}
