// GOLDEN PATH CODE
// This is an example of an accessible paginated list of items, with an unknown number of pages
// It is based on the recommendations in the accessibility and client side dynamic caching document
// https://quip-amazon.com/yadCAoxGCGVD/Accessibility-and-Client-Side-Dynamic-Caching-The-best-of-both-worlds

// Components
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { AccessibleSuspenseLoading } from '../accessibility/accessibleSuspenseLoading';
import { SignupCard, SignupHeader } from './signupCard';
// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useSuspensePaginatedRedVelvetQuery,
  usePaginatedRedVelvetQuery,
} from '../../hooks/paginatedRedVelvetQuery';
import { useUrlPage } from '../../hooks/attendance/urlPage';
import { usePaginationStrings } from '../../hooks/localization/paginationStrings';
import { useUser } from '../../hooks/user';
import { externalLinks } from '../../data/externalLinks';
import { DISPLAY_ATTRIBUTES_TYPE } from '../shifts/shift';

export type SignupsProps = {
  startDate?: Date;
  endDate?: Date;
  sort: 'newest-first' | 'oldest-first';
  displayAttributes: DISPLAY_ATTRIBUTES_TYPE[];
};

export function SuspenseSignups({ startDate, endDate, sort, displayAttributes }: SignupsProps) {
  const { t } = useTranslation(['activity', 'translation']);
  const { page } = useUrlPage(1);
  const { userId } = useUser();

  // GOLDEN PATH NOTE
  // We are using the suspense query here for accessibility, since it will make the
  // accessible loading in the parent work correctly
  const { queryResult } = useSuspensePaginatedRedVelvetQuery(
    {
      redVelvetQueryKey: [
        'getDetailedSignupsByQueryParam',
        {
          alias: userId,
          descending: sort === 'newest-first',
          startDateTime: startDate ? `${startDate.toISOString()}` : undefined,
          endDateTime: endDate ? `${endDate.toISOString()}` : undefined,
        },
      ],
    },
    page
  );
  if (queryResult.error) {
    return (
      <Alert
        type="error"
        statusIconAriaLabel={t('signups.errorAria')}
      >{`${queryResult.error}`}</Alert>
    );
  }

  return (
    <Cards
      empty={
        <Box textAlign="center">
          <SpaceBetween direction="vertical" size="s">
            <b className="pseudoHeader">{t('signups.noActivities')}</b>
            <div>{t('signups.noActivitiesSubtitle')}</div>
            <Button
              variant="primary"
              data-aci-analytics-name="myActivity_search"
              href={externalLinks.myActivity.search}
            >
              {t('signups.exploreActivities')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      cardDefinition={{
        header: (detailedSignup) => <SignupHeader {...detailedSignup} />,
        sections: [
          {
            id: 'signup',
            content: (detailedSignup) => (
              <SignupCard {...detailedSignup} displayAttributes={displayAttributes} />
            ),
          },
        ],
      }}
      cardsPerRow={[{ cards: 1 }]}
      items={queryResult.data?.signups || []}
    />
  );
}

function SignupPagination() {
  const paginationStrings = usePaginationStrings('signups.pagination', ['activity', 'translation']);
  const { userId } = useUser();

  // GOLDEN PATH NOTE
  // Use largest page to store the last page that has ever been visited,
  // so the user can easily return to any previously visited page
  const [largestPage, setLargestPage] = useState(1);

  // GOLDEN PATH NOTE
  // In this case, we are using the URL Query Params as our page management system,
  // but we could also use the pages from the query if we wanted the component to
  // be uncontrolled
  const { page, setPage } = useUrlPage(1);
  const { queryResult } = usePaginatedRedVelvetQuery(
    {
      redVelvetQueryKey: ['getDetailedSignupsByQueryParam', { alias: userId }],
    },
    largestPage
  );

  // GOLDEN PATH NOTE
  // If we are currently loading the largest page, or there is another page after the largest one
  // we should mark the pagination as open ended since we don't actually know what the largest page will be'
  const isOpenEnded = queryResult.isLoading || queryResult.data?.page !== undefined;

  return (
    <Pagination
      ariaLabels={paginationStrings}
      currentPageIndex={page}
      onChange={({ detail }) => {
        setPage(detail.currentPageIndex);
        if (detail.currentPageIndex > largestPage) {
          setLargestPage(detail.currentPageIndex);
        }
      }}
      openEnd={isOpenEnded}
      pagesCount={largestPage}
    />
  );
}

export function SignupList({ startDate, endDate, sort, displayAttributes }: SignupsProps) {
  const { t } = useTranslation(['activity', 'translation']);

  return (
    <SpaceBetween direction="vertical" size="s">
      {/* GOLDEN PATH NOTE
            For accessibility reasons, we always want to show the pagination, even if there is no data.
            This allows users to rapidly paginate even while the content of another page is loading */}
      <Header actions={<SignupPagination />} />
      {/* GOLDEN PATH NOTE
                Since we are actually displaying loaded data here, we want to make sure it is accessible 
                We need to use AccessibleSuspenseLoading RIGHT before the component that provides a suspense
                query to make sure the suspense contract is upheld.  We also name the child with the Suspense
                name to indicate the presence of the contract */}
      <AccessibleSuspenseLoading loadingText={t('signups.loading')}>
        <SuspenseSignups
          startDate={startDate}
          endDate={endDate}
          sort={sort}
          displayAttributes={displayAttributes}
        />
      </AccessibleSuspenseLoading>
      <Header actions={<SignupPagination />} />
    </SpaceBetween>
  );
}
